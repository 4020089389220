import styled from "styled-components";

const Page = styled.main`
  background-color: ${({ theme }) => theme.palette.background.grey};
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: ${({ theme }) => theme.spacing(4)}px;
  padding-bottom: 0;
  width: 100%;

  @media (min-width: 1140px) {
    padding: ${({ theme }) => theme.spacing(6)}px;
    padding-bottom: 0;
  }
`;

Page.Container = styled.div`
  display: grid;
  grid-column-gap: ${({ theme }) => theme.spacing(4)}px;
  grid-row-gap: ${({ theme }) => theme.spacing(4)}px;
  grid-template-columns: 1fr 1fr;
  margin: 0 auto;
  max-width: 1500px;
  width: 100%;

  @media (min-width: 1140px) {
    grid-column-gap: ${({ theme }) => theme.spacing(8)}px;
    grid-row-gap: ${({ theme }) => theme.spacing(8)}px;
  }
`;

Page.Title = styled.div`
  grid-column: span 2;
`;

Page.Section = styled.section`
  display: flex;
  grid-column: span 2;

  @media (min-width: ${({ theme }) => theme.breaks.md}) {
    grid-column: span 1;
  }

  @media (min-width: 1140px) {
    display: block;
    grid-column: span 2;
  }
`;

Page.NextAction = styled.div`
  grid-column: span 2;
  align-items: center;
  display: flex;
  justify-content: center;
`;

Page.SocialBox = styled.div`
  background-color: ${({ theme }) => theme.palette.background.white};
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(2)}px
    ${({ theme }) => theme.spacing(3)}px;
  width: 100%;
  max-width: 240px;
`;

export default Page;
