import styled from 'styled-components';

export const Footer = styled.footer`
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  grid-column: span 2;
  padding-bottom: ${({ theme }) => theme.spacing(6)}px;
  text-align: center;
  width: 100%;
`;
