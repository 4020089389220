import React from "react";
import styled, { css, keyframes } from "styled-components";

import SplitHero from "../../components/split-hero";
import Text from "../../components/text";

import babelLogo from "../../images/babel-logo.svg";
import jestLogo from "../../images/jest-logo.svg";
import nodeLogo from "../../images/node-logo.svg";
import npmLogo from "../../images/npm-logo.svg";
import webpackLogo from "../../images/webpack-logo.svg";
import yarnLogo from "../../images/yarn-logo.svg";

const AnimationToRight = keyframes`
    to{
        transform: translate(60%);
    }
`;

const AnimationToLeft = keyframes`
    to{
        transform: translate(-60%);
    }
`;

export const Animation = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  padding: 5% 0;
  width: 100%;
`;

Animation.Row = styled.div`
  animation: ${AnimationToLeft} 120s linear alternate both infinite;
  display: inline-flex;
  justify-content: space-around;
  height: 30%;
  width: 240%;

  ${({ bottom }) =>
    bottom &&
    css`
      align-self: flex-end;
      animation: ${AnimationToRight} 120s linear alternate both infinite;
    `}
`;

Animation.Img = styled.img`
  flex: none;
  height: 70%;
  padding: 0 ${({ theme }) => theme.spacing(3)}px;

  @media (min-width: 1140px) {
    height: 90%;
  }

  ${({ jest }) =>
    jest &&
    css`
      height: 50%;
      @media (min-width: 1140px) {
        height: 60%;
      }
    `}
`;

function JavaScriptSection() {
  return (
    <SplitHero>
      <SplitHero.Content>
        <Text variant="h3" component="h2" shade="light">
          Javascript.
        </Text>

        <Text variant="p" shade="mid">
          I have a vast amount of experience with JavaScript, building websites,
          apps and games. I have worked with ECMA Script 2015 and have
          professional experience with modern tooling such as Node, Webpack,
          Babel and the package managers npm and yarn. I have worked in TDD
          environments and have used the testing framework Jest.
        </Text>
      </SplitHero.Content>

      <SplitHero.Media>
        <SplitHero.Media.Content>
          <Animation>
            <Animation.Row top>
              <Animation.Img src={babelLogo} alt="Babel" />
              <Animation.Img src={webpackLogo} alt="Webpack" />
              <Animation.Img src={nodeLogo} alt="Node.js" />
              <Animation.Img src={jestLogo} jest alt="Jest" />
              <Animation.Img src={yarnLogo} alt="Yarn" />
              <Animation.Img src={npmLogo} alt="Node Package Manager" />
            </Animation.Row>
            <Animation.Row bottom>
              <Animation.Img src={nodeLogo} alt="Node.js" />
              <Animation.Img src={webpackLogo} alt="Webpack" />
              <Animation.Img src={babelLogo} alt="Babel" />
              <Animation.Img src={npmLogo} alt="Node Package Manager" />
              <Animation.Img src={yarnLogo} alt="Yarn" />
              <Animation.Img src={jestLogo} jest alt="Jest" />
            </Animation.Row>
          </Animation>
        </SplitHero.Media.Content>
      </SplitHero.Media>
    </SplitHero>
  );
}

export default JavaScriptSection;
