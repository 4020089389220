import React from 'react';
import Helmet from 'react-helmet';

import Button from '../../components/button';
import Footer from '../../components/footer';
import Icon from '../../components/icon';
import Page from '../../components/page';
import TransitionLink from '../../components/transition-link';
import Text from '../../components/text';

import HtmlCssSection from './section-html';
import JamStackSection from './section-jam';
import JavaScriptSection from './section-js';
import ReactSection from './section-react';
import WebGLSection from './section-webgl';

function TechnologiesPage() {
  return (
    <Page>
      <Page.Container>
        <Helmet title="Steve Meredith - Technologies"></Helmet>

        <Page.Title>
          <Text variant="h2" component="h1">
            Technologies.
          </Text>
        </Page.Title>

        <Page.Section>
          <JavaScriptSection />
        </Page.Section>

        <Page.Section>
          <ReactSection />
        </Page.Section>

        <Page.Section>
          <HtmlCssSection />
        </Page.Section>

        <Page.Section>
          <JamStackSection />
        </Page.Section>

        <Page.Section>
          <WebGLSection />
        </Page.Section>

        <Page.NextAction>
          <Button as={TransitionLink} to="/experience/">
            Experience <Icon icon="arrowRight" marginLeft={1} />
          </Button>
        </Page.NextAction>

        <Footer />
      </Page.Container>
    </Page>
  );
}

export default TechnologiesPage;
