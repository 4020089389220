import React from "react";

import SplitHero from "../../components/split-hero";
import Text from "../../components/text";
import threeLogo from "../../images/three-logo.svg";

function WebGLSection() {
  return (
    <SplitHero>
      <SplitHero.Content>
        <Text variant="h3" component="h2" shade="light">
          WebGL.
        </Text>

        <Text variant="p" shade="mid">
          I have experience working with WebGL and have used both Three.js and
          Microsoft's Babylon.js in multiple professional projects.
        </Text>
      </SplitHero.Content>

      <SplitHero.Media>
        <SplitHero.Media.Content>
          <SplitHero.Media.Img src={threeLogo} alt="Three.js" />
        </SplitHero.Media.Content>
      </SplitHero.Media>
    </SplitHero>
  );
}

export default WebGLSection;
